.react-calendar {
  width: 350px;
  max-width: 100%;
  background: white;
  line-height: 1.125em;
  padding: 1rem;
  border-radius: 0.5rem;
}

.react-calendar--doubleView {
  width: 700px;
}

.react-calendar--doubleView .react-calendar__viewContainer {
  display: flex;
  margin: -0.5em;
}

.react-calendar--doubleView .react-calendar__viewContainer > * {
  width: 50%;
  margin: 0.5em;
}

.react-calendar__navigation {
  display: flex;
  height: 44px;
  margin-bottom: 1.5em;
}

.react-calendar__navigation button {
  min-width: 44px;
  background: none;
  font-size: 1rem;
  padding: 0.5rem;
  border-radius: 0.375rem;
  color: #111827;
  font-weight: 600;
}

.react-calendar__navigation button.react-calendar__navigation__label {
  background: none !important;
  color: #000 !important;
  font-weight: 600;
}

.react-calendar__navigation button.react-calendar__navigation__label:hover {
  background-color: #f3f4f6 !important;
}

.react-calendar__navigation__prev-button:hover,
.react-calendar__navigation__next-button:hover {
  background-color: #f3f4f6;
}

.react-calendar__navigation button[disabled] {
  opacity: 0.5;
  cursor: not-allowed;
}

/* Hide year navigation buttons */
.react-calendar__navigation__prev2-button,
.react-calendar__navigation__next2-button {
  display: none;
}

.react-calendar__month-view__weekdays {
  text-align: center;
  text-transform: none;
  font-weight: 500;
  font-size: 0.875em;
  margin-bottom: 0.75em;
}

.react-calendar__month-view__weekdays__weekday {
  padding: 0.75em;
  color: #4b5563;
}

.react-calendar__month-view__weekdays__weekday abbr {
  text-decoration: none;
  cursor: default;
}

.react-calendar__month-view__weekNumbers .react-calendar__tile {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.75em;
  font-weight: bold;
}

.react-calendar__month-view__days__day--neighboringMonth {
  color: #9ca3af;
}

.react-calendar__tile {
  max-width: 100%;
  padding: 0.75rem;
  background: none;
  text-align: center;
  line-height: 16px;
  font-size: 0.875rem;
  border-radius: 0.375rem;
  color: inherit;
}

.react-calendar__tile:not(.font-bold) {
  color: #9ca3af !important;
  cursor: not-allowed;
}

.react-calendar__tile.font-bold {
  color: #2563eb;
}

.react-calendar__tile.font-bold:hover {
  background-color: #dbeafe;
}

.react-calendar__tile:disabled {
  background-color: #f3f4f6;
  cursor: not-allowed;
  opacity: 0.5;
}

.react-calendar__tile--now {
  background: #dbeafe;
  font-weight: 600;
}

.react-calendar__tile--active {
  background: #2563eb;
  color: white !important;
  font-weight: 600;
}

/* Override hover on active tile */
.react-calendar__tile--active:hover {
  background: #2563eb !important; /* Keep the same active blue */
}

/* Dark mode overrides */
@media (prefers-color-scheme: dark) {
  .react-calendar {
    background: rgb(31, 41, 55);
    color: white;
  }

  .react-calendar__navigation button {
    color: white;
  }

  .react-calendar__month-view__weekdays__weekday {
    color: #d1d5db;
  }

  .react-calendar__tile.font-bold {
    color: #60a5fa;
  }

  .react-calendar__tile.font-bold:hover {
    background-color: rgb(30, 58, 138);
  }

  .react-calendar__tile--now {
    background: rgb(30, 58, 138);
  }

  .react-calendar__tile--active {
    background: rgb(37, 99, 235);
  }

  .react-calendar__navigation__prev-button:hover,
  .react-calendar__navigation__next-button:hover {
    background-color: rgb(55, 65, 81);
  }

  .react-calendar__navigation button.react-calendar__navigation__label {
    color: #fff !important;
  }

  .react-calendar__navigation button.react-calendar__navigation__label:hover {
    background-color: rgb(55, 65, 81) !important;
  }

  /* Override hover on active tile */
  .react-calendar__tile--active:hover {
    background: rgb(37, 99, 235) !important; /* Keep the same dark mode active blue */
  }

  .react-calendar__tile:not(.font-bold) {
    color: #4b5563 !important;
  }
}

/* Add this near the top, after .react-calendar definition */
.react-calendar__navigation__label > span {
  color: #000 !important;
  font-weight: 700 !important;
}

/* And in the dark mode section */
@media (prefers-color-scheme: dark) {
  .react-calendar__navigation__label > span {
    color: #fff !important;
  }
}